// Routes for Auth and User related pages
export default [{
  path: '/auth/signin',
  name: 'auth-signin',
  component: () => import('@/pages/auth/SigninPage.vue'),
  meta: {
    layout: 'auth',
    ifLoggedIn: 'dashboard',
    ifPending: 'auth-verify-email'
  }
}, {
  path: '/auth/signup',
  name: 'auth-signup',
  component: () => import('@/pages/auth/SignupPage.vue'),
  meta: {
    layout: 'auth',
    ifLoggedIn: 'dashboard',
    ifPending: 'auth-verify-email'
  }
}, {
  path: '/auth/signout',
  name: 'auth-signout',
  component: () => import('@/pages/auth/SignoutPage.vue'),
  meta: {
    layout: 'auth',
    loggedIn: true
  }
}, {
  path: '/auth/verify-email',
  name: 'auth-verify-email',
  component: () => import('@/pages/auth/VerifyEmailPage.vue'),
  meta: {
    layout: 'auth',
    pending: true
  }
}, {
  path: '/auth/forgot-password',
  name: 'auth-forgot',
  component: () => import('@/pages/auth/ForgotPasswordPage.vue'),
  meta: {
    layout: 'auth',
    guest: true
  }
}, {
  path: '/auth/reset-password/:token',
  name: 'auth-reset',
  component: () => import('@/pages/auth/ResetPasswordPage.vue'),
  meta: {
    layout: 'auth'
  }
}]
