import JokeService from './joke.service'

// console.log('Creating Store ', process.env.NODE_ENV)
const state = {
  status: {
    joke: ''
  }
}

//actions that call a service then required mutations
const actions = {
  //get a random joke
  getJoke ({ commit }) {
    return JokeService.getJoke().then(
      (joke) => {
        commit('haveJoke', joke)

        return Promise.resolve(joke)
      },
      (error) => {
        commit('failure')

        return Promise.reject(error)
      }
    )
  },
  //send a joke
  sendJoke ({ state, commit, rootState }, channel) {
    return JokeService.sendJoke(channel).then(
      (me) => {
        commit('auth/meReceived', me, { root: true })
        // commit('jokeSent', me)

        return Promise.resolve(true)
      },
      (error) => {
        commit('failure')

        return Promise.reject(error)
      }
    )
  }
}

//Mutations
const mutations = {
  //Me object returned, put in the store
  haveJoke (state, joke) {
    state.status.joke = joke['joke']
  },
  // jokeSent (state, me, rootState) {
  //   rootState.auth.status.smsCredits = me['sms_credits']
  // },
  //We have failed or logged out, initialise store
  failure (state) {
    state.status.joke = ''
  }
}

//useful getters to interrogate store
const getters = {
  joke () {
    return state.status.joke
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
