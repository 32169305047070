export default [{
  path: '/delivery',
  name: 'delivery`',
  component: () => import( '@/pages/main/Delivery.vue'),
  meta: {
    layout: 'simple'
  }
}, {
  path: '/dashboard',
  name: 'dashboard`',
  component: () => import( '@/pages/main/Dashboard.vue'),
  meta: {
    layout: 'simple'
  }
}, {
  path: '/account',
  name: 'account',
  component: () => import( '@/pages/main/AccountSettings.vue'),
  meta: {
    layout: 'simple',
    auth: true
  }
}, {
  path: '/3d',
  name: 'threeD',
  component: () => import( '@/pages/main/ThreeD.vue'),
  meta: {
    layout: 'simple'
  }
},{
  path: '/about',
  name: 'about',
  component: () => import( '@/pages/main/About.vue'),
  meta: {
    layout: 'simple'
  }
},{
  path: '/gallery',
  name: 'gallery',
  component: () => import( '@/pages/main/Gallery.vue'),
  meta: {
    layout: 'simple'
  }
}]
