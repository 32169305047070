<template>
  <div>
    <toolbar-main />
    <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
      <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
        <div class="layout-content ma-auto w-full">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ToolbarMain from '../components/toolbar/ToolbarMain'

export default {
  components: {
    ToolbarMain
  }
}
</script>

<style scoped>
.layout-side {
  width: 420px;
}

.layout-content {
  max-width: 480px;
}
</style>
