// Our main router file, all gets included here.
import Vue from 'vue'
import Router from 'vue-router'

// Routes
import PagesRoutes from './pages.routes'
import AuthRoutes from './auth.routes'
import LandingRoutes from './landing.routes'
import MainRoutes from './main.routes'

//Guards
import { authGuard } from '@/router/auth.guard'
import { initGuard } from '@/router/init.guard'

Vue.use(Router)

export const routes = [
  {
    path: '/contactus',
    name: 'contact',
    component: () => import('@/pages/landing/Contact.vue'),
    meta: {
      layout: 'auth'
    }
  }, {
    path: '/blank',
    name: 'blank',
    component: () => import('@/pages/BlankPage.vue'),
    meta: {
      auth: true
    }
  },
  ...PagesRoutes,
  ...AuthRoutes,
  ...LandingRoutes,
  ...MainRoutes,
  {
    // If we make ir here, we're lost
    path: '*',
    name: 'error',
    component: () => import('@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error'
    }
  }]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return initGuard(from, to, next)

})

router.beforeEach((to, from, next) => {
  return authGuard(to, from, next)

})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
