import axios from 'axios'
import authHeader from './auth-header'

// const API_URL = process.env.NODE_ENV === 'production' ? 'https://api1.tybx.io/api/auth/' : 'http://api1tybxio.test/api/auth/'
const API_URL = process.env.NODE_ENV === 'production' ? 'https://api1.tybx.io/api/auth/' : 'https://tybxapi.ddev.site/api/auth/'

class AuthService {
// !! token storage - currently localstorge !!
  storageStore (token) {
    localStorage.setItem('access_token', JSON.stringify(token))
  }

  storageRemove () {
    localStorage.removeItem('access_token')
  }

  // Post server login service
  login (user) {
    this.storageRemove()

    return axios.post(API_URL + 'login', {
      email: user.email,
      password: user.password
    })
      .then((response) => {
        if (response.data['access_token']) {
          this.storageStore(response.data['access_token'])
        }

        return response.data
      })
  }

  // Post server register service
  register (user) {
    this.storageRemove()

    return axios
      .post(API_URL + 'register', {
        name: user.name,
        email: user.email,
        password: user.password
      })
      .then((response) => {
        if (response.data['access_token']) {
          this.storageStore(response.data['access_token'])
        }

        return response.data
      })
  }

  // Post server service to re-send a verification email
  passwordResetEmail (email) {
    return axios
      .post(API_URL + 'forgot-password', {
        email: email
      })
      .then((response) => {
        return response.data
      })
  }

  // Post server service to send a password reset email
  passwordReset (reset) {
    return axios
      .post(API_URL + 'reset-password', {
        email: reset.email,
        password: reset.newPassword,
        password_confirmation: reset.newPassword,
        token: reset.token
      })
      .then((response) => {
        if (response.data['access_token']) {
          this.storageStore(response.data['access_token'])
        }

        return response.data
      })
  }

  // Post server service to log out
  logout () {
    return axios.post(API_URL + 'logout', {}, { headers: authHeader() })
      .then((response) => {
        this.storageRemove()

        return response.data
      })
  }

  // Post server service to re-send a verification email
  resendVerificationEmail () {
    return axios.post(API_URL + 'resend', {}, { headers: authHeader() })
      .then((response) => {
        return response.data
      })
  }

  // Post server service to get user data
  async init () {

    //If we don't have a token, don't post to server
    if (!authHeader()) {
      const error = { message: 'No token stored' }

      return Promise.reject(error)
    }

    //get all about current user using stored tokem
    return await axios.post(API_URL + 'me', {}, { headers: authHeader() })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        this.storageRemove()

        return Promise.reject(error)
      })
  }

  // Post server service to set users phone number
  setPhoneNumber (phone) {
    return axios.post(API_URL + 'set-phone-number', { phone_number: phone }, { headers: authHeader() })
      .then((response) => {
        return response.data
      })
  }

  // Post server service to set verify phone number
  verifyPhone (verificationCode) {
    return axios.post(API_URL + 'verify-phone', { verification_code: verificationCode }, { headers: authHeader() })
      .then((response) => {
        return response.data
      })
  }

  // Post server service to remove phone
  removePhone () {
    return axios.post(API_URL + 'remove-phone', {}, { headers: authHeader() })
      .then((response) => {
        return response.data
      })
  }

  // Post server service to remove phone
  changeName (name) {
    return axios.post(API_URL + 'change-name',
      { name: name },
      { headers: authHeader() })
      .then((response) => {
        return response.data
      })
  }

}

export default new AuthService()
