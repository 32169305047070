import AuthService from './auth.service'

// console.log('Creating Store ', process.env.NODE_ENV)
const state = {
  status: {
    email: '',
    synced: false,
    loggedIn: false,
    verified: false,
    userName: '',
    smsCredits: '0',
    phoneNumber: '',
    phoneVerified: false
  }
}

//actions that call a service then required mutations
const actions = {
  //login action
  login ({ commit }, user) {
    return AuthService.login(user).then(
      (me) => {
        commit('meReceived', me)

        return Promise.resolve(me)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  //logout action
  logout ({ commit }) {
    return AuthService.logout().then(
      () => {
        commit('meFailure')

        return Promise.resolve(true)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  //register action
  register ({ commit }, user) {
    return AuthService.register(user).then(
      (me) => {
        commit('meReceived', me)

        return Promise.resolve(me)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  //action to initialise user data
  async init ({ commit }) {
    return await AuthService.init()
      .then(
        (me) => {
          commit('meReceived', me)

          return Promise.resolve(me)
        },
        (error) => {
          commit('meFailure')

          return Promise.reject(error)
        }
      )
  },
  //action to resend verification email
  resendVerificationEmail ({ commit }) {
    return AuthService.resendVerificationEmail().then(
      (me) => {
        commit('meReceived', me)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
  //action to send reset email
  sendReset ({ commit }, email) {
    return AuthService.passwordResetEmail(email).then(
      () => {
        commit('meFailure')

        return Promise.resolve(true)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  //action to reset password
  passwordReset ({ commit }, reset) {
    return AuthService.passwordReset(reset).then(
      (me) => {
        commit('meReceived', me)

        return Promise.resolve(true)
      },
      (error) => {
        commit('meFailure')

        return Promise.reject(error)
      }
    )
  },
  //action to set phone number
  setPhoneNumber ({ commit }, phoneNumber) {
    return AuthService.setPhoneNumber(phoneNumber).then(
      (me) => {
        commit('meReceived',me)

        return Promise.resolve(true)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
  //action to set verify phone
  verifyPhone ({ commit }, verificationCode) {
    return AuthService.verifyPhone(verificationCode).then(
      (me) => {
        commit('meReceived',me)

        return Promise.resolve(true)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
  //action to remove phone
  removePhone ({ commit }) {
    return AuthService.removePhone().then(
      (me) => {
        commit('meReceived',me)

        return Promise.resolve(true)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  },
  //action to remove phone
  changeName ({ commit }, name) {
    return AuthService.changeName(name).then(
      (me) => {
        commit('meReceived',me)

        return Promise.resolve(true)
      },
      (error) => {
        return Promise.reject(error)
      }
    )
  }

}

//Mutations
const mutations = {
  //Me object returned, put in the store
  meReceived (state, me) {
    console.log('Me Received')
    state.status.email = me['email']
    state.status.synced = true
    state.status.loggedIn = true
    state.status.verified = me['verified']
    state.status.userName = me['name']
    state.status.smsCredits = me['sms_credits']
    state.status.phoneNumber = me['mobile_number']
    state.status.phoneVerified = !!me['mobile_number_verified_at']
  },
  //We have failed or logged out, initialise store
  meFailure (state) {
    state.status.synced = true
    state.status.loggedIn = false
    state.status.verified = false
    state.status.userName = ''
    state.status.smsCredits = 0
    state.status.phoneNumber = ''
    state.status.phoneVerified = false
  }
}

//useful getters to interrogate store
const getters = {
  synced () {
    return state.status.synced
  },
  isLoggedIn () {
    return state.status.loggedIn
  },
  isPending () {
    return state.status.loggedIn && (!state.status.verified)
  },
  isVerified () {
    return state.status.verified
  },
  userName () {
    return state.status.userName
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
