import axios from 'axios'
import authHeader from '../auth/auth-header'

// const API_URL = process.env.NODE_ENV === 'production' ? 'https://api1.tybx.io/api/joke/' : 'http://api1tybxio.test/api/joke/'
const API_URL = process.env.NODE_ENV === 'production' ? 'https://api1.tybx.io/api/joke/' : 'https://tybxapi.ddev.site/api/joke/'

class JokeService {
  // Post server login service
  getJoke () {
    return axios.post(API_URL + 'get-random-joke', {}, { headers: authHeader() })
      .then((response) => {
        return response.data
      })
  }
  sendJoke (channel) {
    return axios.post(API_URL + 'send-joke', { channel: channel }, { headers: authHeader() })
      .then((response) => {
        return response.data
      })
  }

}

export default new JokeService()
