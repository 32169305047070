//Main Init Guard. If we need to fetch data from the server, pause whilst getting it.

import store from '../store'
import authHeader from '@/store/auth/auth-header'

export async function initGuard(to, from, next) {
  //If we are already synced, simply return
  if (store.getters['auth/synced'])  return next()

  //If we have no token stored, return
  if (!('Authorization' in authHeader()) ) return next()

  // console.log('We have a token, lets get data from server')
  // Do not proceed until we have received server response
  await store.dispatch('auth/init')

  return next()
}
