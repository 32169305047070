import Vue from 'vue'
import Vuex from 'vuex'

// Global vuex
import AppModule from './app'
import AuthModule from './auth'
import landingModule from './landing'
import JokeModule from './joke'

Vue.use(Vuex)

/**
 * Main Vuex Store
 */
const store = new Vuex.Store({
  modules: {
    app: AppModule,
    auth: AuthModule,
    landing: landingModule,
    joke: JokeModule
  }
})

export default store
