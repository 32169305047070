<template>
  <v-app>
    <component :is="currentLayout" v-if="isRouterLoaded">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>
  </v-app>
</template>

<script>

import config from './configs'

// Layouts
import simpleLayout from './layouts/SimpleLayout'
import landingLayout from './layouts/LandingLayout'
import authLayout from './layouts/AuthLayout'
import errorLayout from './layouts/ErrorLayout'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/

export default {
  components: {
    simpleLayout,
    landingLayout,
    authLayout,
    errorLayout
  },
  computed: {
    isRouterLoaded: function () {
      return this.$route.name !== null
    },
    currentLayout: function () {
      const layout = this.$route.meta.layout || 'simple'

      return layout + 'Layout'
    }
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  }

}
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
