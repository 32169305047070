<template>
  <div>
    <toolbar-main />
    <v-main>
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <page-footer></page-footer>
  </div>
</template>

<script>
import ToolbarMain from '../components/toolbar/ToolbarMain'
import PageFooter from '../components/landing/PageFooter'

export default {
  components: {
    ToolbarMain,
    PageFooter
  }
}
</script>
<style>
</style>
