<template>
  <div>
    <toolbar-main />
    <v-main>
      <router-view :key="$route.fullPath"></router-view>
      <v-footer color="transparent">
        <v-container class="py-1">
          <v-divider class="my-3"></v-divider>
          <div class="text-center caption">
            © 2021. All Rights Reserved
          </div>
        </v-container>
      </v-footer>
    </v-main>
  </div>
</template>

<script>
import ToolbarMain from '../components/toolbar/ToolbarMain'

export default {
  components: {
    ToolbarMain
  }
}
</script>
<style>
</style>
