<template>
  <!-- Site Navigation Top Toolbar-->
  <v-app-bar flat height="90" min-width="300">
    <v-container class="py-0 px-0 pa-sm-1 fill-height pa-">
      <!-- LEFT - Display either full logo and name graphic or just logo dependant on viewport-->
      <router-link to="/" class="d-none d-sm-flex align-center text-decoration-none">
        <img :src="require('@/assets/images/tybx-logo.png')" height="56" />
      </router-link>
      <router-link to="/" class="d-sm-none align-center text-decoration-none">
        <img :src="require('@/assets/images/smalllogo.png')" height="56" />
      </router-link>

      <v-spacer px-0></v-spacer>

<!--      Easter Egg Dropdown-->
      <v-menu offset-y left transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn class="ma-1" outlined v-on="on">
            <v-icon>mdi-egg-easter</v-icon>
            <div class="hidden-sm-and-down">For Fun</div>
          </v-btn>
        </template>

        <v-list nav color="error">

          <v-list-item to="/dashboard">
            <v-list-item-icon>
              <v-icon small>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-1"></v-divider>
          <v-list-item to="/delivery">
            <v-list-item-icon>
              <v-icon small>mdi-duck</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Takeaway/Delivery</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item to="/gallery">
            <v-list-item-icon>
              <v-icon small>mdi-image-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Gallery</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-1"></v-divider>

          <v-list-item to="/3d">
            <v-list-item-icon>
              <v-icon small>mdi-rotate-3d</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Raw 3d</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-1"></v-divider>

          <v-list-item to="/about">
            <v-list-item-icon>
              <v-icon small>mdi-information-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>About</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>

      <!-- If not logged in, show sign up and sign in-->
      <div v-if="!isLoggedIn">
        <v-btn outlined class="ma-0" to="/auth/signup">
          <v-icon>mdi-account-plus-outline</v-icon>
          <div class="hidden-xs-only">Sign Up</div>
        </v-btn>
        <v-btn outlined class="ma-0" to="/auth/signin">
          <v-icon>mdi-login-variant</v-icon>
          <div class="hidden-xs-only">Sign In</div>
        </v-btn>
      </div>

      <!-- If logged in, show account dropdown-->
      <v-menu v-if="!!isLoggedIn" offset-y left transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn
            class="ma-2"
            outlined
            v-on="on"
          >
            <v-icon>mdi-account-outline</v-icon>
            <div class="hidden-xs-only">{{ userName }}</div>
          </v-btn>
        </template>

        <v-list nav color="primary">

          <v-list-item to="/account">
            <v-list-item-icon>
              <v-icon small>mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Account Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider class="my-1"></v-divider>

          <!--          <v-list-item to="/mobile">-->
          <!--            <v-list-item-icon>-->
          <!--              <v-icon small>mdi-phone</v-icon>-->
          <!--            </v-list-item-icon>-->
          <!--            <v-list-item-content>-->
          <!--              <v-list-item-title>Mobile Setup</v-list-item-title>-->
          <!--            </v-list-item-content>-->
          <!--          </v-list-item>-->

          <!--          <v-divider class="my-1"></v-divider>-->

          <v-list-item to="/auth/signout">
            <v-list-item-icon>
              <v-icon small>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
      </v-menu>

    </v-container>
  </v-app-bar>
</template>

<script>
import config from '../../configs'

import { mapState } from 'vuex'

export default {
  data () {
    return {}
  },
  computed: mapState({
    isLoggedIn: (state) => state.auth.status.loggedIn,
    userName: (state) => state.auth.status.userName
  })
}
</script>
